<template>
  <a-space>
    <input ref="upload" style="display: none" type="file" @change="onChange" />
    <a-button type="primary" :size="size" :loading="loading" @click="onClick">
      {{ url ? "重新上传" : "上传" }}
    </a-button>
    <a v-if="url" target="_blank" :href="url">预览</a>
    <a v-if="url" href="#" class="danger" @click.prevent="remove">删除</a>
  </a-space>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    url: {
      type: String,
    },
    size: {
      type: String,
      default: "default",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();

      if (!files.length) {
        return;
      }
      data.append("files", files[0]);
      this.loading = true;

      uploadFile(data)
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            this.$emit("change", res[0].completePath);
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="less" scoped>
.file {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 8px;
}
.file img {
  width: 100%;
}
</style>