<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="right">
        <a-button type="primary" size="small" @click="add">添加</a-button>
      </div>
      <Padding />
      <a-table
        bordered
        :data-source="
          Array.isArray(detail.orgHonorVoList) ? detail.orgHonorVoList : []
        "
        :pagination="false"
      >
        <a-table-column title="荣誉类型">
          <template slot-scope="text">
            <!-- @change="text.rank = null" -->
            <a-select
              v-model="text.type"
              placeholder="请选择"
              size="small"
              style="width: 120px"
            >
              <a-select-option
                v-for="item in honorLogo"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </template>
        </a-table-column>
        <a-table-column title="荣誉等级">
          <template slot-scope="text">
            <a-select
              v-model="text.rank"
              placeholder="请选择"
              size="small"
              style="width: 120px"
            >
              <a-select-option
                v-for="item in text.type
                  ? text.type === 'internal'
                    ? honorLevel
                    : externalHonorLevel
                  : []"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </template>
        </a-table-column>
        <a-table-column title="所获荣誉">
          <template slot-scope="text">
            <a-input
              style="width: 120px"
              size="small"
              placeholder="请输入"
              v-model="text.name"
            />
          </template>
        </a-table-column>

        <a-table-column title="授予日期">
          <template slot-scope="text">
            <a-date-picker
              style="width: 120px"
              size="small"
              placeholder="请选择"
              v-model="text.issueDate"
            />
          </template>
        </a-table-column>
        <a-table-column title="授予机构">
          <template slot-scope="text">
            <a-input
              style="width: 120px"
              size="small"
              placeholder="请输入"
              v-model="text.issueUnit"
            />
          </template>
        </a-table-column>

        <a-table-column title="附件" align="center">
          <template slot-scope="text, row, index">
            <Uploader
              size="small"
              :url="text.attachment"
              @remove="text.attachment = ''"
              @change="(url) => onUpload(text, index, url)"
            />
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text, row, index">
            <a-space>
              <a href="#" @click.prevent="save(text)">保存</a>
              <a href="#" class="danger" @click.prevent="remove(text, index)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>
<script>
import Uploader from "../employee/components/uploader.vue";
import { fetchDetailTree } from "@/api/setting/organization";
import moment from "moment";
import { mapGetters } from "vuex";

import request from "@/api/request";

export default {
  components: {
    Uploader,
  },

  data() {
    return {
      detail: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    lists() {
      return this.findDataDict("talentMap");
    },

    // 荣誉标识
    honorLogo() {
      return (
        this.lists.find((item) => item.value === "honorLogo").children || []
      );
    },
    // 荣誉等级
    honorLevel() {
      return (
        this.lists.find((item) => item.value === "honorLevel").children || []
      );
    },
    externalHonorLevel() {
      return (
        this.lists.find((item) => item.value === "externalHonorLevel")
          .children || []
      );
    },
  },

  mounted() {
    const { id = "" } = this.$route.query;
    if (!id) return;
    this.id = id;
    this.getDetail();
  },

  methods: {
    getDetail() {
      fetchDetailTree({
        uuid: this.id,
      }).then((res) => {
        if (res) {
          this.detail = {
            ...res,
            orgHonorVoList: Array.isArray(res.orgHonorVoList)
              ? res.orgHonorVoList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate ? moment(item.issueDate) : null,
                  };
                })
              : [],
          };
        }
      });
    },
    add() {
      if (Array.isArray(this.detail.orgHonorVoList)) {
        this.detail.orgHonorVoList.push({});
      } else {
        this.detail.orgHonorVoList = [{}];
      }
    },
    save(item) {
      const params = {
        pid: this.id,
        ...item,
        issueDate: item.issueDate
          ? item.issueDate.format("YYYY-MM-DD")
          : undefined,
      };
      if (item.id) {
        request({
          url: "/user-service/orgHonor/modify",
          method: "post",
          data: params,
        }).then(() => {
          this.getDetail();
        });
      } else {
        request({
          url: "/user-service/orgHonor/save",
          method: "post",
          data: params,
        }).then(() => {
          this.getDetail();
        });
      }
    },

    remove(item, index) {
      if (item.id) {
        request({
          url: "/user-service/orgHonor/del",
          method: "post",
          data: {
            id: item.id,
          },
        }).then(() => {
          this.getDetail();
        });
      } else {
        this.detail.orgHonorVoList.splice(index, 1);
      }
    },

    onUpload(item, index, url) {
      this.detail.orgHonorVoList.splice(index, 1, {
        ...item,
        attachment: url,
      });
    },
  },
};
</script>